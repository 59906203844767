<template>
    <div class="nf">
        <div class="nf__container">
            <img :src="img" alt="404 not found" />
            <p class="nf__tips">抱歉，您要查看的页面不存在！</p>
            <span href="" class="nf__back" @click="backToHome">返回首页</span>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            img: '//pages.anjukestatic.com/usersite/touch/img/404.png'
        };
    },
    methods: {
        backToHome() {
            location.href = '/';
        }
    },
    head() {
        return {
            title: '对不起，没有找到这个页面'
        };
    },
    errorCaptured() {
        return false;
    }
};
</script>
<style lang="scss" scoped>
.nf__container {
    text-align: center;
    margin: 12%;
}

.nf__container img {
    margin: 0 auto;
    width: 245px;
    height: 139px;
    position: relative;
    left: 47px;
}

.nf__tips {
    line-height: 1;
    font-size: 14px;
    color: #555;
    margin: 15px 0 27px;
}

.nf__back {
    font-size: 15px;
    display: block;
    line-height: 40px;
    width: 100%;
    height: 40px;
    color: #fff;
    background-color: #62ac01;
}
</style>
