import { Base64 } from 'js-base64';

const isWuba = () => {
    const { host } = location;

    return host.indexOf('.58.com') > -1;
}

const doLogin = (params = {}) => {
    const WUBA_LOGIN_URL = 'https://m.m.58.com/login/?path=';
    const ANJUKE_LOGIN_URL = 'https://m.anjuke.com/member/login/?history='
    const { url = location.href } = params;

    if (isWuba()) {
        location.href = WUBA_LOGIN_URL + encodeURIComponent(url);
        return;
    }

    location.href = ANJUKE_LOGIN_URL + Base64.encode(url);
}

export default doLogin;