import Vue from 'vue';
import throttle from '~/utils/throttle';

const gesture = {
    install(Vue) {
        // 上拉加载指令
        Vue.directive('reachBottom', {
            inserted(el, binding) {
                const bottom = Number(el.dataset.bottom) || 50;
                let scrollTop, elHeight, windowHeight;
                el.addEventListener(
                    'touchmove',
                    throttle(() => {
                        scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                        elHeight = el.offsetHeight;
                        windowHeight = window.screen.height;

                        if (elHeight - scrollTop <= windowHeight + bottom) {
                            binding.value.call(this);
                        }
                    }),
                    200
                );
            },

            unbind(el, binding) {
                el.removeEventListener('touchmove', binding.value);
            }
        });
        // 下拉刷新指令
        Vue.directive('reachTop', {
            inserted(el, binding) {
                let startY, endY;
                el.addEventListener('touchstart', function(e) {
                    startY = e.targetTouches[0].clientY;
                });
                el.addEventListener('touchmove', function(e) {
                    endY = e.targetTouches[0].clientY;
                });
                el.addEventListener('touchend', function(e) {
                    if (endY - startY >= 30) {
                        binding.value.call(this, e);
                    }
                });
            },

            unbind(el, binding) {
                el.removeEventListener('touchend', binding.value);
            }
        });
        // 判断滑动方向,返回左滑||右滑||上滑||下滑
        Vue.directive('touchDirection', {
            inserted(el, binding) {
                let startX;
                let endX;
                let startY;
                let endY;
                let lastScrollTop = 0;
                let scrollTop = 0;
                let lastScrollLeft = 0;
                let scrollLeft = 0;
                let oldtime = new Date().getTime();
                let newTime;

                // 有滚动条兼容ios惯性滚动监听scroll事件，节流
                if (binding.modifiers.fullScreen) {
                    window.addEventListener('scroll', function(e) {
                        newTime = new Date().getTime();
                        if (newTime - oldtime < 200) return;

                        scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                        scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;

                        // scrollTop > 1 hack ios下拉回弹
                        if (lastScrollTop < scrollTop && scrollTop > 1) {
                            binding.value.call(this, 'slideUp');
                        } else if (lastScrollTop > scrollTop) {
                            binding.value.call(this, 'slideDown');
                        }

                        if (lastScrollLeft < scrollLeft) {
                            binding.value.call(this, 'slideLeft');
                        } else if (lastScrollLeft > scrollLeft) {
                            binding.value.call(this, 'slideRight');
                        }

                        lastScrollTop = scrollTop;
                        lastScrollLeft = scrollLeft;
                        oldtime = newTime;
                    });
                    return false;
                }

                el.addEventListener('touchstart', function(e) {
                    startY = e.targetTouches[0].clientY;
                    startX = e.targetTouches[0].clientX;
                });

                el.addEventListener('touchmove', function(e) {
                    newTime = new Date().getTime();
                    if (newTime - oldtime < 200) return;

                    endY = e.targetTouches[0].clientY;
                    endX = e.targetTouches[0].clientX;

                    const DY = endY - startY;
                    const DX = endX - startX;

                    if (Math.abs(DY / DX) < 1) {
                        if (DX > 0) {
                            binding.value.call(this, 'slideRight');
                        } else {
                            binding.value.call(this, 'slideLeft');
                        }
                    } else if (DY < 0) {
                        binding.value.call(this, 'slideUp');
                    } else {
                        binding.value.call(this, 'slideDown');
                    }
                    oldtime = newTime;
                });

                el.addEventListener('touchend', function(e) {
                    binding.value.call(this, 'touchend');
                });
            },

            unbind(el, binding) {
                el.removeEventListener('touchend', binding.value);
                el.removeEventListener('touchstart', binding.value);
                el.removeEventListener('touchmove', binding.value);
                window.removeEventListener('scroll', binding.value);
            }
        });
    }
};

export default () => {
    Vue.use(gesture);
};
