import Vue from 'vue';

export default () => {
    const globalComponents = require.context('~/components/global', true, /\.vue/);
    globalComponents.keys().forEach(fileName => {
        const component = globalComponents(fileName);
        const componentName = fileName
            .split('/')
            .pop()
            .replace(/\.\w+$/, '');

        Vue.component(componentName, component.default || component);
    });
};
