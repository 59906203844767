import _ from 'lodash';
import request from 'axios';
/**
 * 比较 ajk app 版本
 * @param v1 此版本
 * @param v2 要比较的版本
 * @return 1 代表大于当前版本，0 代表版本相同，-1 代表小于当前版本
 */
export function compareVersion(v1, v2) {
    if (!v1 || !v2) {
        return -1;
    }
    const currVersion = v1.split('.');
    const currLength = currVersion.length;
    const minVersion = v2.split('.');
    const minLength = minVersion.length;
    const length = currLength > minLength ? currLength : minLength;
    let currValue, minValue;
    for (let i = 0; i < length; i++) {
        // 进行数字转换，否则 ‘10’ < '7'
        currValue = Number(currVersion[i]);
        minValue = Number(minVersion[i]);

        if (currValue > 0 && minValue > 0) {
            if (currValue < minValue) {
                return -1;
            } else if (currValue > minValue) {
                return 1;
            } else {
                if (i === length - 1) {
                    return 0;
                }
                continue;
            }
        } else if (currLength > i && minLength < i) {
            return currValue > 0 ? 1 : 0;
        } else if (currLength < i && minLength > i) {
            return minValue > 0 ? -1 : 0;
        } else {
            return 0;
        }
    }
}
/**
 * 判断url是不是安居客host
 * @param url
 * @returns {boolean|*}
 */
export function isAnjuke({ url } = {}) {
    if (_.isString(url) && url) {
        return url.includes('.anjuke.');
    }
    return false;
}

/**
 * 判断URL是不是58host
 * @param url
 * @returns {boolean|*}
 */
export function isWuba({ url } = {}) {
    if (_.isString(url) && url) {
        return url.includes('.58.');
    }
    return false;
}
/**
 * 判断空值
 * @param param
 * @returns {boolean}
 */
export const isEmpty = param =>
    param === '' ||
    param === null ||
    param === undefined ||
    JSON.stringify(param) === '{}' ||
    (Array.isArray(param) && param.length <= 0);

/**
 * 获取字符串长度,中文算2个字符
 * @param val
 * @returns {number}
 */
export function getByteLen(val) {
    let byteValLen = 0;
    for (let i = 0; i < val.length; i++) {
        // eslint-disable-next-line no-control-regex
        if (val[i].match(/[^\x00-\xFF]/gi) != null) byteValLen += 2;
        else byteValLen += 1;
    }
    return byteValLen;
}
/**
 * 字符串截取
 * @param val
 * @param max
 * @param suffix
 * @returns {string}
 */
export function getByteVal(val, max, suffix) {
    let returnValue = '';
    let byteValLen = 0;
    for (let i = 0; i < val.length; i++) {
        // eslint-disable-next-line no-control-regex
        if (val[i].match(/[^\x00-\xFF]/gi) != null) byteValLen += 2;
        else byteValLen += 1;
        if (byteValLen > max) {
            suffix && (returnValue += suffix);
            break;
        }
        returnValue += val[i];
    }
    return returnValue;
}

/**
 * 转换对象为字符串
 * @param object
 * @param joinChart
 * @returns {string}
 */
export function changeObjectToString({ object, joinChart } = {}) {
    if (_.isPlainObject(object) && !_.isEmpty(object)) {
        const temp = [];
        _.forEach(object, (value, key) => {
            temp.push(`${key}=${value}`);
        });
        return temp.join(joinChart || '&');
    }
    return '';
}

/**
 * 提取URL中参数
 * @param url
 * @returns {{}}
 */
export function parseQueryString(url) {
    if (!url || !_.isString(url)) {
        console.log(`parseQueryString error:参数类型不符`);
        return {};
    }
    const obj = {};
    let keyvalue = [];
    let key = '';
    let value = '';
    const paraString = url.substring(url.indexOf('?') + 1, url.length).split('&');
    for (const i in paraString) {
        keyvalue = paraString[i].split('=');
        key = keyvalue[0];
        value = keyvalue[1];
        obj[key] = value;
    }
    return obj;
}
// 微信分享
export function addWXShare(params) {
    /* eslint-disable */
    const defaultShareImg = 'https://pages.anjukestatic.com/usersite/touch/img/global/defaultImg/share_img.png';
    const script = document.createElement('script');
    script.src = '//res.wx.qq.com/open/js/jweixin-1.0.0.js';
    script.async = true;
    const content = document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0];
    script.onload = function() {
        request({
            method: 'get',
            // url: '//api.anjuke.com/weixin/common/share_js_ticket',
            // url: '//m.anjuke.com/xinfang/api/weixin/ticket',
            url: '/xinfang/api/weixin/ticket',
            params: {
                url: location.origin + location.pathname + location.search
            }
        })
            .then(res => {
                const result = res.data.result;
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: result.appid, // 必填，公众号的唯一标识
                    timestamp: result.timestamp, // 必填，生成签名的时间戳
                    nonceStr: result.noncestr, // 必填，生成签名的随机串
                    signature: result.signature, // 必填，签名，见附录1
                    jsApiList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'onMenuShareQQ'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                });

                const wxTitle = params.title;
                const wxDesc = params.description;
                const wxLink = params.url;
                const wxImgUrl = params.image || defaultShareImg;

                function wxAjkLog(msg) {}

                wx.ready(function() {
                    wx.onMenuShareAppMessage({
                        title: wxTitle, // 分享标题
                        desc: wxDesc, // 分享描述
                        link: wxLink, // 分享链接
                        imgUrl: wxImgUrl, // 分享图标
                        success() {
                            // 用户确认分享后执行的回调函数
                            wxAjkLog('onMenuShareAppMessage-success');
                        },
                        cancel() {
                            // 用户取消分享后执行的回调函数
                            wxAjkLog('onMenuShareAppMessage-cancel');
                        }
                    });
                    wx.onMenuShareTimeline({
                        title: wxTitle, // 分享标题
                        link: wxLink, // 分享链接
                        imgUrl: wxImgUrl, // 分享图标
                        success() {
                            // 用户确认分享后执行的回调函数
                            wxAjkLog('onMenuShareTimeline-success');
                        },
                        cancel() {
                            // 用户取消分享后执行的回调函数
                            wxAjkLog('onMenuShareTimeline-cancel');
                        }
                    });
                    wx.onMenuShareQQ({
                        title: wxTitle, // 分享标题
                        desc: wxDesc, // 分享描述
                        link: wxLink, // 分享链接
                        imgUrl: wxImgUrl, // 分享图标
                        success() {
                            // 用户确认分享后执行的回调函数
                            wxAjkLog('onMenuShareQQ-success');
                        },
                        cancel() {
                            // 用户取消分享后执行的回调函数
                            wxAjkLog('onMenuShareQQ-cancel');
                        }
                    });
                });
            })
            .catch(() => {});
        // $.ajax({
        //     // url: '//api.anjuke.com/weixin/common/share_js_ticket',
        //     // url: '//m.anjuke.com/xinfang/api/weixin/ticket',
        //     url: '/xinfang/api/weixin/ticket',
        //     data: {
        //         url: location.origin + location.pathname + location.search
        //     },
        //     dataType: 'json',
        //     success(res) {
        //         // //wxsdk 用于微信，qq分享
        //         // wx.config({
        //         //     debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        //         //     appId: res.appId, // 必填，公众号的唯一标识
        //         //     timestamp: res.timestamp, // 必填，生成签名的时间戳
        //         //     nonceStr: res.nonceStr, // 必填，生成签名的随机串
        //         //     signature: res.signature, // 必填，签名，见附录1
        //         //     jsApiList: [
        //         //         'onMenuShareTimeline',
        //         //         'onMenuShareAppMessage',
        //         //         'onMenuShareQQ'
        //         //     ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        //         // });
        //         // wxsdk 用于微信，qq分享

        //     }
        // });
    };
    content.appendChild(script);
    /* eslint-enable */
}

/**
 * 是否在微信浏览器中
 * @param userAgent
 * @returns {boolean}
 */
export function checkWeappByUserAgent(userAgent) {
    if (!userAgent) {
        return false;
    }
    const ua = userAgent.toLowerCase();
    const res = ua.match(/MicroMessenger/i);
    return res && res[0] === 'micromessenger';
}

/**
 * 是否在小程序中
 * @param userAgent
 * @returns {boolean}
 */
export function checkMiniProgramByUserAgent(userAgent) {
    if (!userAgent) {
        return false;
    }
    const ua = userAgent.toLowerCase();
    const res = ua.match(/miniProgram/i);
    return res && res[0] === 'miniprogram';
}
/*
是否在抖音小程序中
*/
export function checkToutiaoProgramByUserAgent(userAgent) {
    if (!userAgent) {
        return false;
    }
    const ua = userAgent.toLowerCase();
    const res = ua.match(/toutiaomicroapp/i);
    return res && res[0] === 'toutiaomicroapp';
}

export function loadScript(src, dom, isCrossorigin = false, callback, async = false) {
    (function() {
        const vrscript = document.createElement('script');
        vrscript.setAttribute('charset', 'utf-8');
        vrscript.src = src;
        isCrossorigin && vrscript.setAttribute('crossorigin', 'anonymous');
        async && vrscript.setAttribute('async', true);
        // vrscript.async = true;
        vrscript.onload = function() {
            callback && callback();
        };
        const s = document.querySelector(dom) || document.getElementsByTagName('body')[0];
        s.appendChild(vrscript);
    })();
}

export const stopClickThrough = (bodyCls => {
    let scrollTop;
    return {
        afterOpen() {
            const doc = document.scrollingElement || document.body || document.documentElement;
            scrollTop = doc.scrollTop;
            document.body.classList.add(bodyCls);
            document.body.style.top = -scrollTop + 'px';
        },
        beforeClose() {
            const doc = document.scrollingElement || document.body || document.documentElement;
            document.body.classList.remove(bodyCls);
            // scrollTop lost after set position:fixed, restore it back.
            doc.scrollTop = scrollTop;
            document.body.style.top = '';
        }
    };
})('dialog-open');

// 获取参数
export function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        // eslint-disable-next-line eqeqeq
        if (pair[0] == variable) {
            return pair[1];
        }
    }
    return false;
}

// 判断环境
export function getEnv() {
    const domain = window.location.host;
    return {
        isAjk: domain.includes('anjuke.com'),
        isWuba: domain.includes('58.com'),
        // 增加小程序环境判断
        isWxMiniProgram: window.__wxjs_environment === 'miniprogram',
        // eslint-disable-next-line unicorn/prefer-starts-ends-with
        isSwanMiniProgram: /swan\//.test(window.navigator.userAgent) || /^webswan-/.test(window.name),
        isQuickApp: /hap/.test(window.navigator.userAgent),
        isAliMiniProgram: window.navigator.userAgent.includes('AlipayClient')
    };
}

export function _addParamsToUrlForOne(url, key, ...rest) {
    if (!url) return 'javascript:void(0);';
    const keyisArray = Array.isArray(key);
    const keyisObject = Object.prototype.toString.call(key) === '[object Object]';
    // 判断哪个参数是seoSourceType
    let seoSourceType = null;
    // 如果key传入是对象,就有可能是value或者原本的seoSourceType位置
    if (keyisObject && typeof rest[0] === 'number') {
        seoSourceType = rest[0];
    } else {
        seoSourceType = rest[1];
    }
    if (seoSourceType === 99) return url && url.split('?')[0];

    const q = url.includes('?');
    q ? (url += '&') : (url += '?');
    let query = '';
    // 检测是单值还是多值
    // 多值情况
    if (keyisArray) {
        for (let i = 0; i < key.length; i++) {
            if (key[i] && rest[0][i]) {
                query += `${key[i]}=${rest[0][i]}&`;
            }
        }
    } else if (keyisObject) {
        for (const [itemKey, itemValue] of entries(key)) {
            query += `${itemKey}=${itemValue}&`;
        }
    } else {
        // 单值情况
        query = `${key}=${rest[0]}&`;
    }
    if (query.length > 0) {
        url += query;
    }
    return url.slice(0, -1);
}

/**
 * url增加参数
 * @param {String|Array} url 原始url;支持单url与批量数组
 * @param {String|Array|Object} key 键值,支持字符串,数组,对象;如果是对象就无value值
 * @param {String|Array} value 值,支持字符串与数组;当key为对象的时候此值可以换成seoSourceType
 * @param {Number} seoSourceType 判断爬虫来源
 */
export function addParamsToUrl(url, key, value, seoSourceType) {
    if (Array.isArray(url)) {
        return url.map(item => _addParamsToUrlForOne(item, key, value, seoSourceType));
    }
    return _addParamsToUrlForOne(url, key, value, seoSourceType);
}
export function entries(obj) {
    const ownProps = Object.keys(obj);
    let i = ownProps.length;
    const resArray = new Array(i); // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];
    return resArray;
}

export function colorRgb(color) {
    // 16进制颜色值的正则
    const reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    // 把颜色值变成小写
    let Color = color.toLowerCase();
    if (reg.test(Color)) {
        // 如果只有三位的值，需变成六位，如：#fff => #ffffff
        if (Color.length === 4) {
            let colorNew = '#';
            for (let i = 1; i < 4; i += 1) {
                colorNew += Color.slice(i, i + 1).concat(Color.slice(i, i + 1));
            }
            Color = colorNew;
        }
        // 处理六位的颜色值，转为RGB
        const colorChange = [];
        for (let i = 1; i < 7; i += 2) {
            colorChange.push(parseInt('0x' + Color.slice(i, i + 2)));
        }
        return 'RGB(' + colorChange.join(',') + ')';
    } else {
        return Color;
    }
}

export const filterXSS = v => {
    if (isEmpty(v)) {
        return '';
    }
    if (typeof v !== 'string') {
        v = JSON.stringify(v);
    }
    const htmlEscapes = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        // eslint-disable-next-line prettier/prettier
        javascript: '',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;'
    };
    Object.keys(htmlEscapes).forEach(char => {
        if (v.includes(char)) {
            v = v.split(char).join(htmlEscapes[char]);
        }
    });
    return v;
};
