import { ANJUKE, WUBA } from '~/global/config-api';

const getConfig = (isDev, isWuba) => {
    const commonConfig = isWuba ? WUBA : ANJUKE;
    return isDev ? commonConfig.DEV_CONFIG : commonConfig.PRODUCTION_CONFIG;
};

const getCityId = (isWuba, { anjuke, wuba }) => {
    const cityId = isWuba ? wuba.id : anjuke.id;

    return cityId || 11;
};

const mountCity = (options, cityId) => {
    const MAP = { get: 'params', post: 'data' };
    const { method = 'get' } = options;

    options[MAP[method]] = Object.assign(
        {
            city_id: cityId
        },
        options[MAP[method]]
    );

    return options;
};

const getCookieObjByString = cookieString => {
    const keyValuePairs = cookieString.split(';');
    const cookiesObj = {};
    keyValuePairs.forEach(v => {
        const [key, value] = v.split('=');

        if (!key || !value) return;
        cookiesObj[key.trim()] = value.trim();
    });
    return cookiesObj;
};

const getCookieStringByObj = cookieObj => {
    const cookieArr = [];
    Object.keys(cookieObj).forEach(key => {
        cookieArr.push(key + '=' + cookieObj[key]);
    });

    return cookieArr.join(';');
};

export { getConfig, getCityId, mountCity, getCookieObjByString, getCookieStringByObj };
