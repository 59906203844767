// eslint-disable-next-line no-unused-vars
const isYunTest = process.env.WCloud_Env === 'Test';
// eslint-disable-next-line no-unused-vars
const ajkTestURL = 'http://m.anjuke.test/';

const COMMON_DEV_CONFIG = {
    serverApiURL: 'http://m.anjuke.test/',
    clientApiURL: ''
};

const AnjukeProduct = 'http://m.tj.a.ajkdns.com/';

const ANJUKE = {
    // DEV_CONFIG: COMMON_DEV_CONFIG,
    DEV_CONFIG: {
        serverApiURL: AnjukeProduct,
        clientApiURL: '/'
    },
    PRODUCTION_CONFIG: {
        // serverApiURL: isYunTest ? ajkTestURL : 'http://m.tj.a.ajkdns.com/',
        serverApiURL: AnjukeProduct,
        clientApiURL: '/'
    }
};

const WUBA = {
    DEV_CONFIG: COMMON_DEV_CONFIG,
    PRODUCTION_CONFIG: {
        serverApiURL: 'http://msale58.a.ajkdns.com/',
        clientApiURL: '/'
    }
};

export { ANJUKE, WUBA };
