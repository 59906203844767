import Vue from 'vue';
import HomeAPI from '../api/index';
import _ from 'lodash';

export default function createModule() {
    let serverApi = {
        baseView: 'getHomeBaseData',
        houseprice: 'getHomePrice',
        seoInfo: 'getHomeSeoInfo',
        esf_recommend: 'getHomePropSaleList',
        xf_themeList: 'getHomePropXfTheme',
        xf_recommend: 'getHomePropXfList',
        hot_questionsList: 'getHomeHotQuestion',
        get_ashen_switch:'getAshenSwitch',
        get_home_m_base_data:'getHomeMBaseData'
    };
    let clientApi = {};

    let stateToApi = _.merge({}, serverApi, clientApi);

    /*stateToApi = {
        baseView: "getHomeBaseData",
        houseprice: "getHomePrice",
        saleList: "getHomePropSaleList",
        xfTheme: "getHomePropXfTheme",
        xfList: "getHomePropXfList",
        recommend: "getHomePropRecommend",
        hotQuestions: "getHomeHotQuestion",
        hotTags: "getHomeHotTag",
        hotCommunity: "getHomeHotCommunity",
        xfHotTag: "getHomeXfHotTag",
        xfHotSearch: "getHomeXfHotSearch",
        seoInfo:"getHomeSeoInfo"
    }*/
    let getters = {};
    Object.keys(stateToApi).forEach((value, index) => {
        getters[value] = state => state[value];
    });
    return {
        moduleName: 'homeModule',
        moduleEntity: {
            namespaced: true,
            state: () => ({
                baseView: {},
                houseprice: {},
                saleList: {},
                xfTheme: {},
                xfList: {},
                recommend: {},
                hotQuestions: {},
                hotTags: {},
                hotCommunity: {},
                xfHotTag: {},
                xfHotSearch: {},
                seoDescription: ''
            }),
            mutations: {
                SET_DATA: (state, { key, data }) => {
                    Vue.set(state, key, data);
                },
                SET_SEO_DESCRIPTION(state, value) {
                    state.seoDescription = value;
                }
            },
            actions: {
                GET_STATE_DATA: ({ commit }, { key, params }) => {
                    // if (existApi[key]) {
                    return HomeAPI[stateToApi[key]](params)
                        .then(data => {
                            commit('SET_DATA', { key, data });
                        })
                        .catch(error => {
                            return error;
                        });
                    // } else {
                    //     commit('SET_DATA', key, mockData(key))
                    // }
                }
            },
            getters
        },
        serverApi,
        clientApi,
        stateToApi
    };
}

/*function mockData(key) {
    let mockData = {
        baseView: {
            "toutiao": {
                "image": "https://pic1.ajkimg.com/display/anjuke/1b69fc740acfada8e4115b06ec8c106f/108x108.jpg",
                "webview_url": "",
                "couple": [
                    {
                        "title": "居早报：减负基调不变 个税扣除多项细则正完善",
                        "default_img": "https://pic1.ajkimg.com/display/anjuke/e0e48196d20b6ea52683690f59914c2a/108x108.jpg"
                    },
                ]
            },
            "menu": [
                {
                    "url": "http://m.zhangx.dev.anjuke.com/sh/xinfang/",
                    "title": "新房",
                    "type": "xf"
                }
            ],
            "tool": [
                {
                    "url": "http://m.zhangx.dev.anjuke.com/sh/xinfang/",
                    "title": "安居问答",
                    "type": "qa",
                }
            ],
            "seo_source_type": "0",
            "is_redirct_xf": "0"
        }
    }

    return mockData[key] || null
}*/
