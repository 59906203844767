<template>
    <div>
        <nuxt />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { isWuba } from '../utils/common';

const APP_KEYS = ['Anjuke', 'Broker', 'Wuba', 'Weixin', 'Miniprogram', 'AJLZ'];

export default {
    head() {
        const { source_h5: sourceH5 } = this.feCommon;
        const source = JSON.stringify({ source_h5: sourceH5 });
        return {
            bodyAttrs: {
                'data-sourceh5': source
            }
        };
    },
    provide() {
        const { app = {}, auth, host, cityV2, origin } = this.feCommon;
        const { APP_TYPES = [] } = app;
        const prefix = 'is';

        APP_KEYS.forEach(key => {
            app[prefix + key] = app.type === APP_TYPES[key.toUpperCase()];
        });
        app.isWeb = !APP_KEYS.some(key => app[prefix + key]);
        return {
            APP: app,
            AUTH: auth,
            CITY: isWuba({ url: origin }) ? cityV2.wuba : cityV2.anjuke,
            ENV: host.includes('58') ? 'wuba' : 'anjuke'
        };
    },
    computed: {
        ...mapGetters({
            feCommon: 'getFeCommon'
        })
    },
    errorCaptured() {
        return false;
    }
};
</script>
