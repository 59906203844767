/*
 * @Author       : yilei
 * @Date         : 2022-04-15 21:48:22
 * @LastEditTime : 2023-10-17 19:47:41
 * @LastEditors  : yilei
 */
import Vue from 'vue';
import VueAwesomeSwiper from 'vue-awesome-swiper/dist/ssr';
import Soj from '@ajk/soj';

export default context => {
    const { isDev, store, query = {} } = context;
    const { app = {} } = store.getters.getFeCommon;
    if ((isDev && app.value) || (query && query.___eruda___)) {
        const eruda = require('eruda');
        eruda.init();
        eruda.position({ x: 200, y: 200 });
    }
    Vue.use(Soj);
    Vue.use(VueAwesomeSwiper, {});
    (function({ methods, variables }) {
        for (let i = 0; i < methods.length; i++) {
            if (typeof window[methods[i]] !== 'function') {
                window[methods[i]] = function() {
                    return '';
                };
            }
        }
        for (let i = 0; i < variables.length; i++) {
            if (typeof window[variables[i]] === 'undefined') {
                window[variables[i]] = '';
            }
        }
    })({
        methods: ['getShareContents', 'getCollectContents'],
        // variables: ['ajkDidBecomeActive', 'ajkViewDidDisappear']
        variables: []
    });
    if (!window.browser) {
        window.browser = {
            versions: (function() {
                const u = navigator.userAgent;
                const uLower = u.toLowerCase();
                return {
                    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
                    android: u.includes('Android') || u.includes('Linux'),
                    iPhone: u.includes('iPhone'),
                    iPad: u.includes('iPad'),
                    isWx: u.includes('MicroMessenger'),
                    isMiniProgram: uLower.includes('miniprogram'),
                    isWb: u.includes('Weibo')
                };
            })()
        };
    }
};
