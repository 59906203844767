import Vue from 'vue';
import LazyLoad from 'vue-lazyload';

export default () => {
    Vue.use(LazyLoad, {
        preLoad: 1.3,
        error: 'https://pages.anjukestatic.com/usersite/touch/img/comm/nopic_list2.png',
        loading: 'https://pages.anjukestatic.com/usersite/touch/img/comm/nopic_list2.png',
        attempt: 2
    });
};
