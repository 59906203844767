import Vue from 'vue';
import Bridge from '@ajk/fe-hybrid-lib';
import old from './old';
import doH5Login from './h5.login';
import qs from 'qs';
function isOpenWebView(url = '', isApp = false) {
    if (!isApp) return false;
    return url.indexOf('http') === 0;
}

export default context => {
    const { route, store } = context;
    const feCommon = context.store.getters.getFeCommon;
    const { app = {} } = feCommon;
    const { type, version, APP_TYPES } = app;
    // const bridge = new Bridge(type, version);
    const bridge = Bridge({
        type,
        version,
        isDev: true
    });

    Vue.prototype.$driver = bridge;
    // old嫁接
    old(bridge);

    Vue.prototype.$jump = (url, params = {}, isOpenNewWebview = false, needReplace = false) => {
        const isApp = type === APP_TYPES.WUBA || type === APP_TYPES.ANJUKE;
        if (type === APP_TYPES.MINIPROGRAM) {
            if(needReplace){
                wx.miniProgram.redirectTo({ url });
            }else{
                wx.miniProgram.navigateTo({ url });
            }
            return;
        }
        if (!isOpenNewWebview && isOpenWebView(url, isApp)) {
            bridge.goWebview({ url, isFinish: params.isFinish, params });
            return;
        }
        if (app.isWeb || app.isWeixin) {
            location.href = url;
        } else {
            console.log(url)
            bridge.goNative(url);
        }
    };

    Vue.prototype.$autoLogin = callback =>
        new Promise((resolve, reject) => {
            if (!type || type === 'weixin') {
                doH5Login();
                return reject({ message: '跳转H5登录页' });
            }
            bridge.getLoginState().then(state => {
                if (state == 0) {
                    callback && callback();
                    resolve();
                } else {
                    return Vue.prototype.$login(callback).then(res => resolve(res));
                }
            })
        });

    Vue.prototype.$login = (callback, redirectUrl = location.href) =>
        new Promise((resolve, reject) => {
            if (type === APP_TYPES.MINIPROGRAM) {
                wx.miniProgram.navigateTo({ url: '/page/my/pages/member/middle' });
                return reject({ message: '跳转小程序登录页' });
            }

            if (!type || type === 'weixin') {
                doH5Login({
                    url: redirectUrl
                });
                return reject({ message: '跳转H5登录页' });
            }

            bridge.doLogin().then(({ info, result }) => {
                if (type === 'wuba' && result == 0) {
                    callback && callback(info);
                    resolve(info);
                    return;
                }
                let params = {
                    member_id: info.memberID,
                    member_token: info.memberToken,
                    chat_id: info.chatID,
                    chat_token: info.chatToken,
                    ajk_auth_ticket: info.ajkAuthTicket
                };
                Vue.prototype
                    .$fetch({
                        url: '/v3/ajax/checkauth/',
                        method: 'post',
                        autoCity: false,
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        data: qs.stringify(params)
                    })
                    .then(res => {
                        callback && callback(res.data || res);
                        return resolve(res.data || res);
                    });
            });
        });

    Vue.mixin({
        beforeRouteUpdate(to, from, next) {
            if (typeof this.$options.sendPv === 'function') {
                const params = this.$options.sendPv();
                if (typeof params === 'string') {
                    this.$soj.sendPv(params);
                } else {
                    this.$soj.sendPv(params.pageName, params.cp);
                }
            }
        },
        beforeMount() {
            if (typeof this.$options.sendPv === 'function') {
                const params = this.$options.sendPv({ store, route });
                if (typeof params === 'string') {
                    this.$soj.sendPv(params);
                } else {
                    this.$soj.sendPv(params.pageName, params.cp);
                }
            }
        }
    });
};
