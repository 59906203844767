<template>
    <img v-lazy="src" :src="loadingImage" :alt="alt" />
</template>
<script>
export default {
    props: {
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loadingImage: 'https://pages.anjukestatic.com/usersite/touch/img/comm/nopic_list2.png'
        };
    }
};
</script>
