import Vue from 'vue';
import { loadScript } from '@/utils/common';
import beidouConfig from '~/beidou.config';

// 忽略的异常
const ignoreErrors = [
    "TypeError:undefined is not an object (evaluating 'window.ucbrowser.novelHistoryByBookIos')",
    "TypeError:undefined is not an object (evaluating 'v.a.get(window.browser,'versions').android')",
    'UnhandledRejection:Non-Error promise rejection captured with keys is message',
    'Error:Script error.',
    'TypeError:window.bannerNight is not a function'
];
const BDSDK = 'https://j1.58cdn.com.cn/beidou-sdk/browser/bundle.min.js';
class DeferredQueue {
    constructor() {
        this.queue = [];
    }

    push(error) {
        this.queue.push(error);
        return this.queue;
    }

    shift() {
        return this.queue.shift();
    }

    clear() {
        this.queue = [];
        return this.queue;
    }

    execute() {
        if (typeof window.Beidou === 'undefined') {
            return false;
        }
        // 按照顺序上报
        this.queue.forEach(item => {
            const { err, info } = item;
            Beidou.captureException(err, info);
        });
        this.clear();
        return true;
    }
}

export default ({ isDev }) => {
    if (isDev) return;
    const queue = new DeferredQueue();
    Vue.config.errorHandler = function(err, vm, info) {
        // 由于此时北斗还未挂载，先将错误塞入堆栈;
        queue.push({ err, info });
    };

    window.onerror = function(msg, url, lineNo, columnNo, error) {
        queue.push({ err: error, info: msg });
    };

    function execute() {
        window.Beidou.init({
            sdk: BDSDK, // sdk地址
            ...beidouConfig,
            ignoreErrors
        });
        // 将之前所有堆栈里面的结果全部弹出
        queue.execute();
        // 替换errorHandler
        Vue.config.errorHandler = function(err, vm, info) {
            window.Beidou.captureException(err, info);
        };
    }
    loadScript(
        BDSDK,
        'head',
        false,
        function callBack() {
            //  这里用轮询检测北斗是不是挂载上了， 以防不同浏览器对async标签的实现不同
            if (typeof window.Beidou !== 'undefined') {
                execute();
            } else {
                setTimeout(callBack, 5000);
            }
        },
        true
    );
};
