// import myfetch from 'create-api';
import Vue from 'vue';

function myfetch(...args) {
    return Vue.prototype.$fetch(...args, false, false);
}

export default {
    // 获取首页基本信息
    // eslint-disable-next-line camelcase
    getHomeBaseData({ city_id }) {
        return myfetch({
            url: '/home/base/data',
            method: 'get',
            data: { city_id }
        });
    },
    // 房价信息
    // eslint-disable-next-line camelcase
    getHomePrice({ city_id }) {
        return myfetch({
            url: '/home/city/price/',
            method: 'get',
            data: { city_id }
        });
    },
    // 二手房推荐
    getHomePropSaleList({ city_id, app_ip }) {
        return myfetch({
            // baseURL: 'http://10.167.69.229:8080',
            // headers: {
            //     HOST: 'm.tj.a.ajkdns.com'
            // },
            url: '/esf-ajax/property/home/prop/sale/list',
            // url: '/home/prop/sale/list',
            method: 'get',
            data: { city_id, app_ip }
        });
    },
    getHomeMBaseData({ city_id, app_ip }) {
        return myfetch({
            // baseURL: 'http://10.167.91.14:8080',
            // headers: {
            //     HOST: 'm.tj.a.ajkdns.com'
            // },
            url: '/esf-ajax/home/m/base_data',
            method: 'get',
            data: { city_id, app_ip }
        });
    },
    //新房推荐-专题包
    getHomePropXfTheme({ city_id }) {
        return myfetch({
            url: '/home/prop/xf/theme',
            method: 'get',
            data: { city_id }
        });
    },
    //新房推荐-房源列表
    getHomePropXfList({ city_id }) {
        return myfetch({
            url: '/home/prop/xf/list'+'?city_id='+city_id,
            method: 'get',
            data: { city_id }
        });
    },
    //猜你喜欢
    getHomePropRecommend({ city_id }) {
        return myfetch({
            url: '/esf-ajax/property/home/prop/recommend',
            // url: '/home/prop/recommend',
            method: 'get',
            data: { city_id }
        });
    },
    //热门问答
    getHomeHotQuestion({ city_id }) {
        return myfetch({
            url: '/home/hot/question',
            method: 'get',
            data: { city_id }
        });
    },
    //二手房联想---
    getHomeHotTag({ city_id, q }) {
        return myfetch({
            url: '/home/hot/tag/',
            method: 'get',
            data: { city_id, q }
        });
    },
    getHomeEsfSuggest({ city_id, kw, extendOptions }) {
        return myfetch({
            url: '/ajax/autocomplete/',
            method: 'get',
            data: {
                city_id,
                kw
            },
            ...extendOptions
        });
    },
    //二手房热门标签
    getHomeHotCommunity({ city_id }) {
        return myfetch({
            url: '/home/hot/tag/',
            method: 'get',
            data: { city_id }
        });
    },
    //新房联想
    getHomeXfSuggest({ city_id }) {
        return myfetch({
            url: '/xinfang/api/home/suggest/',
            method: 'get',
            data: {
                cid: city_id
            }
        });
    },
    //新房热门搜索

    // http://m.fp05.kfs.dev.anjuke.test/xinfang/api/home/ajk_theme_recommend/?cid=11
    getHomeXfHotSearch({ city_id }) {
        return myfetch({
            //url:"http://easymock.fe.corp.anjuke.com/mock/5caab43c973dfd5ee0c630ed/home/hot/xf/tag",
            // url: "/xinfang/api/home/ajk_hot_search_loupans/",
            url: '/home/hot/xf/tag',
            method: 'get',
            data: {
                // cid: city_id
                city_id
            }
        });
    },
    //seo信息
    getHomeSeoInfo({ city_id }) {
        return myfetch({
            url: '/home/seo/info/',
            method: 'get',
            data: {
                cid: city_id
            }
        });
    },

    // 新房跳转区分新房还是爱房楼单
    getXfUrl(loupanId) {
        console.log(loupanId,'getXfUrl')
        return myfetch({
            url: '/xinfang/graphql/loupan/url/',
            method: 'get',
            params: {
                loupan_ids: loupanId,
            }
        });
    },
    // 置灰配置开关接口 @李哲哲
    getAshenSwitch() {
        return myfetch({
            url: 'esf-ajax/activity/ashen_switch',
            method: 'get'
        });
    },
};
