import Vue from 'vue';
import request from 'axios';
import { getConfig, getCityId, mountCity } from './helper';

const Fetch = {};

const transformMethod = method => {
    return method.toLowerCase();
};

const buildBaseURL = (baseURL, config) => {
    return baseURL || config.clientApiURL || '/';
};

const isRequestOk = status => {
    if (status === 'ok' || status === 200 || status === '0' || status === 0 || status === 'true') {
        return true;
    }

    return false;
};

Fetch.install = (vue, { isWuba, isDev, cityId }) => {
    vue.prototype.$fetch = (options, errorControl = true) => {
        const ERROR_MESSAGE = '当前网络状况不佳，请稍后再试';
        const { withCredentials = true, errorMessage = ERROR_MESSAGE } = options;

        let { method = 'get', baseURL = '' } = options;
        const { autoCity = true } = options;
        method = transformMethod(method);
        baseURL = buildBaseURL(baseURL, getConfig(isDev, isWuba));

        if (autoCity) {
            options = mountCity(options, cityId);
        }
        return new Promise((resolve, reject) => {
            request({
                ...options,
                method,
                baseURL,
                withCredentials
            })
                .then(res => {
                    const { data = {} } = res;
                    const { status, code } = data;
                    const isOk = isRequestOk(status || code);

                    if (isOk) {
                        return void resolve(data.data || data.result || data);
                    }

                    const { message, msg } = data;
                    const finalMessage = message || msg || errorMessage;

                    if (errorControl) {
                        vue.prototype.$toast(finalMessage);
                    }

                    reject(data);
                })
                .catch(error => {
                    vue.prototype.$toast(errorMessage);
                    reject(error);
                });
        });
    };
};

export default ({ store, isDev }) => {
    const feCommon = store.getters.getFeCommon;
    const { app, cityV2 } = feCommon;
    const isWuba = (app && app.type === app.APP_TYPES.WUBA) || location.host.includes('.58.');
    const cityId = getCityId(isWuba, cityV2);

    Vue.use(Fetch, { isWuba, isDev, cityId });
};
