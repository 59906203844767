import Vue from 'vue';
/* eslint-disable */
const Toast = {};
Toast.install = function(vue) {
    vue.prototype.$toast = (tips, params = {}) => {
        const opt = {
            position: params.position || 'center',
            duration: params.duration || 2500, // 持续时间
            icon: params.icon,
            size: params.size || 12
        };

        // 同一时间只能弹出一个 toast
        if (document.getElementsByClassName('g-toast').length) {
            return;
        }

        const ToastTpl = vue.extend({
            render: h => {
                const icon = opt.icon ? (
                    <span style={'font-size' + opt.size + 'px'} class={'icon icon-' + opt.icon} />
                ) : (
                    ''
                );

                return (
                    <div class={'g-toast g-toast-' + opt.position}>
                        {icon}
                        <p>{tips}</p>
                    </div>
                );
            }
        });

        const tpl = new ToastTpl().$mount().$el;
        document.body.appendChild(tpl);
        setTimeout(function() {
            document.body.removeChild(tpl);
        }, opt.duration);
    };
};
export default () => {
    Vue.use(Toast);
};
