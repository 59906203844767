 
import Vue from 'vue';

export default (bridge) => {
    const keyMapping = Object.keys(bridge.mapping);
    const valueMapping = Object.values(bridge.mapping);

    // doAction 进行兼容处理
    Vue.prototype.$driver.doAction = Vue.prototype.$driver.getData = (action, params, callback) => {
        // 通过value找到对应key
        const valueIndex = valueMapping.indexOf(action);
        if (valueIndex === -1) {
            // 调用方法有误
            console.log('老bridge嫁接到新bridge中action有误', action);
            return false;
        }
        const key = keyMapping[valueIndex];
        // 调用对应方法
        return bridge[key](params, callback);
    }
}
