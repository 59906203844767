import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6da31f9e = () => interopDefault(import('../../../pages/old/App.vue' /* webpackChunkName: "" */))
const _654072ec = () => interopDefault(import('../../../pages/old/home/home.vue' /* webpackChunkName: "" */))
const _4834f1ac = () => interopDefault(import('../../../pages/old/ajkRank/xiaoqu/communityHot.vue' /* webpackChunkName: "" */))
const _4e312442 = () => interopDefault(import('../../../pages/old/rank/borkerRank/borkerRank.vue' /* webpackChunkName: "" */))
const _25896b9b = () => interopDefault(import('../../../pages/old/rank/storeRank/storeRank.vue' /* webpackChunkName: "" */))
const _0736edbc = () => interopDefault(import('../../../pages/old/ajkRank/qiangshou/index.vue' /* webpackChunkName: "" */))
const _33a1dcc8 = () => interopDefault(import('../../../pages/old/wakeAPP/wakeAPP.vue' /* webpackChunkName: "" */))
const _a86f6f58 = () => interopDefault(import('../../../pages/captcha-wangyi/index.vue' /* webpackChunkName: "" */))
const _377e8f64 = () => interopDefault(import('../../../pages/captcha-geetest/index.vue' /* webpackChunkName: "" */))
const _278246f8 = () => interopDefault(import('../../../pages/trendency/index.vue' /* webpackChunkName: "" */))
const _9d32db8e = () => interopDefault(import('../../../pages/old/ajkRank/synList/synList.vue' /* webpackChunkName: "" */))
const _047db1f9 = () => interopDefault(import('../../../pages/esf-package/index.vue' /* webpackChunkName: "" */))
const _cf363cb8 = () => interopDefault(import('../../../pages/map/index.vue' /* webpackChunkName: "" */))
const _7b189cbb = () => interopDefault(import('../../../pages/agreement/carrier/index.vue' /* webpackChunkName: "" */))
const _4b12b6a4 = () => interopDefault(import('../../../pages/weak/miniProgram/index.vue' /* webpackChunkName: "" */))
const _c9aa195e = () => interopDefault(import('../../../pages/common-material/index.vue' /* webpackChunkName: "" */))
const _7fa72749 = () => interopDefault(import('../../../pages/samsung-download/index.vue' /* webpackChunkName: "" */))
const _6222cd93 = () => interopDefault(import('~/layouts/error.vue' /* webpackChunkName: "" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _6da31f9e,
    alias: "/feapp",
    name: "App",
    children: [{
      path: ":city([a-z]+)/",
      component: _654072ec,
      name: "mHome"
    }, {
      path: "ershou-autorank/xiaoqu",
      component: _4834f1ac,
      name: "communityHot"
    }, {
      path: "ershou-autorank/broker",
      component: _4e312442,
      name: "brokerRank"
    }, {
      path: "ershou-autorank/store",
      component: _25896b9b,
      name: "storeRank"
    }, {
      path: "ershou-autorank/fangyuan/qiangshou/*",
      component: _0736edbc,
      name: "qiangshou"
    }, {
      path: "ershou-autorank/fangyuan/pinzhi/*",
      component: _0736edbc,
      name: "pinzhi"
    }, {
      path: "ershou-autorank/fangyuan/*",
      component: _0736edbc,
      name: "houseRank"
    }, {
      path: "/wake/app",
      component: _33a1dcc8,
      name: "wakeApp"
    }]
  }, {
    path: "/esfcommon-captcha-wy",
    component: _a86f6f58,
    name: "captcha"
  }, {
    path: "/esfcommon-captcha-geetest",
    component: _377e8f64,
    name: "captcha"
  }, {
    path: "/pcesfcommon-captcha-wy",
    component: _a86f6f58,
    name: "captcha"
  }, {
    path: "/pcesfcommon-captcha-geetest",
    component: _377e8f64,
    name: "captcha"
  }, {
    path: "/:city([a-z]+)/trendency/",
    component: _278246f8,
    name: "city-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/:area_id(\\d+)_:block_id(\\d+)/",
    component: _278246f8,
    name: "block-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/:area_id(\\d+)/",
    component: _278246f8,
    name: "area-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/community/:comm_id",
    component: _278246f8,
    name: "community-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/:area_id(\\d+)-q-:shangquan_id(\\d+)/",
    component: _278246f8,
    name: "shangquan-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/:area_py(\\w+)/",
    component: _278246f8,
    name: "area-trendency"
  }, {
    path: "/:city([a-z]+)/trendency/:area_py(\\w+)-q-:shangquan_py(\\w+)/",
    component: _278246f8,
    name: "shangquan-trendency"
  }, {
    path: "/ershou-autorank/synList",
    component: _9d32db8e,
    name: "syn-rankList"
  }, {
    path: "/feapp/ershou-autorank/synList",
    component: _9d32db8e,
    name: "syn-rankList-wb"
  }, {
    path: "/ershou-autorank/common",
    component: _047db1f9,
    name: "syn-com"
  }, {
    path: "/:city([a-z]+)/map/community",
    component: _cf363cb8,
    name: "map"
  }, {
    path: "/xiaoqu/([a-zA-Z0-9]+)/map/community",
    component: _cf363cb8,
    name: "mapwb"
  }, {
    path: "/agreement/carrier/:id",
    component: _7b189cbb,
    name: "agreement"
  }, {
    path: "/weak/miniProgram/esf",
    component: _4b12b6a4,
    name: "weakMiniProgramESF"
  }, {
    path: "/clbCfg/common/material",
    component: _c9aa195e,
    name: "commonMaterial"
  }, {
    path: "/clb_cfg/menu/materialtemplate/materialtemplate",
    component: _c9aa195e,
    name: "commonMaterialPath"
  }, {
    path: "/agreement/samsungDownload",
    component: _7fa72749,
    name: "samsungDownload"
  }, {
    path: "*",
    component: _6222cd93,
    name: "Not Found"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
