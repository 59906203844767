import { setFingerPrintConfig } from '@ajk/network';

const fingerPrintConfig = {
    wbFingerPrintConfig: {
        appkey: '10346',
        version: '1',
        os: '31',
        // 域名由设备指纹根据业务线的主域来申请或复用现有主域，请联系王旭确认
        // 例如https://mail.58.com/接入，设备指纹这边会提前申请好aris.58.com，提供给业务
        domain: 'aris.58.com',
        methods: ['get', 'post']
    },
    ajkFingerPrintConfig: {
        appkey: '10346',
        version: '1',
        os: '31',
        // 域名由设备指纹根据业务线的主域来申请或复用现有主域，请联系王旭确认
        // 例如https://mail.58.com/接入，设备指纹这边会提前申请好aris.58.com，提供给业务
        domain: 'anquan-security.anjuke.com',
        methods: ['post']
    }
};

const whiteList = []; // 白名单配置

const init = isWB => {
    const type = isWB ? 'wbFingerPrintConfig' : 'ajkFingerPrintConfig';
    const config = fingerPrintConfig[type];
    if (config) {
        setFingerPrintConfig({ ...config, whiteList });
    }
};

export default function() {
    if (process.client) {
        const isWB = window.location.host.includes('.58.');
        init(isWB);
    }
}
