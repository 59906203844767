export default function() {
    window.browser = {
        versions: (function() {
            const u = navigator.userAgent;
            const uLower = u.toLowerCase();
            return {
                ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
                android: u.includes('Android') || u.includes('Linux'),
                iPhone: u.includes('iPhone'),
                iPad: u.includes('iPad'),
                isWx: u.includes('MicroMessenger'),
                isMiniProgram: uLower.includes('miniprogram'),
                isWb: u.includes('Weibo')
            };
        })()
    };
}
