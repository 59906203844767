export default function throttle(func, wait = 50) {
    let timeout;
    let context;
    let args;
    let previous = 0;

    const later = function() {
        timeout = null;
        func.apply(context, args);
    };
    return function() {
        context = this;
        args = arguments;

        const now = Date.now();
        const remaining = wait - (now - previous);
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            func.apply(context, args);
        } else {
            clearTimeout(timeout);
            timeout = setTimeout(later, remaining);
        }
    };
}
